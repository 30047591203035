
import { defineComponent } from "vue";
import { UserService } from "@/services/user_service";
import { Passenger } from "@/models/order_modes";

export default defineComponent({
  name: "passenger_list",
  data() {
    return {
      showAlert: false,
      showEditForm: false,
      showAddForm: false,
      count: 0 as number,
      passengerPage: {
        page: 1,
        limit: 5,
      },
      data: [] as Passenger[] | null,
      passengers: [] as Passenger[] | null,
    };
  },
  methods: {
    handleSizeChange(val: number) {
      this.getData();
    },
    // Paginationda joriy sahifa almashishi
    handleCurrentChange(val: number) {
      this.getData();
    },

    // Paginationda keyingi sahifaga o'tish tugmasi bosilgan holat
    handleNextClick(val: number) {
      this.getData();
    },

    // Paginationda olding sahifaga o'tish tugmasi bosilgan holat
    handlePrevClick(val: number) {
      this.getData();
    },
    async getData() {
      const data = await UserService.getPassengerWithPagable(
        this.passengerPage
      );
      this.passengers = data.data;
      this.count = data.count;
    },
  },
  async created() {
    this.getData();
  },
});
