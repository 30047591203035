
import { computed, defineComponent } from "vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import LeftMenu from "@/components/profile/left_menu.vue";
import PassengerList from "@/components/profile/flight_passengers.vue";
import Navigator from "@/components/flight/navigator.vue";
import { FlightSearchResult } from "@/models/flight_models";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

export default defineComponent({
  name: "flight-passenger",
  data() {
    return {
      loading: false,
      isLoginOpen: false,
      searchResult: {} as FlightSearchResult,
    }
  },
  components: {
    TheFooter,
    TheHeader,
    Navigator,
    LeftMenu,
    PassengerList,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' });
    const route = useRoute()
    const router = useRouter()
    const currentUrl = computed(() => router.resolve({
      name: 'home',
      params: { lang: route.params.lang }
    }).href)

    useHead({
      title: computed(() => t('pages.home.title')),
      meta: [
        {
          name: `description`,
          content: computed(() => t('pages.home.description')),
        },
        {
          name: `og:title`,
          content: computed(() => t('pages.home.title')),
        },
        {
          name: `og:description`,
          content: computed(() => t('pages.home.description')),
        },
        {
          name: `og:type`,
          content: 'website',
        },
        {
          name: `og:url`,
          content: computed(() => `https://test-frontend.avione.uz${currentUrl.value}`),
        },
      ],

      link: computed(() => [
        {
          rel: 'canonical',
          href: `https://test-frontend.avione.uz${currentUrl.value}`
        }
      ].concat([
        { lang: 'uz', prefix: 'uz' },
        { lang: 'en', prefix: 'en' },
        { lang: 'ru', prefix: '' },
      ].filter((l) => route.params.lang !== l.prefix).map((l) => {
        return {
          rel: 'alternate',
          hreflang: l.lang,
          href: 'https://test-frontend.avione.uz' + router.resolve({
            name: 'home',
            params: { lang: l.prefix }
          }).href
        }
      })))
    })
  },
});
